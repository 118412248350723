<template>
  <div class="pl-5">
    <div v-if="severity == 'Low'">
      <v-chip small class="ma-2">
        <v-icon left color="success">mdi-thermometer</v-icon>
        {{ severity }}
      </v-chip>
    </div>
    <div v-else-if="severity == 'Medium'">
      <v-chip small class="ma-2">
        <v-icon left color="warning">mdi-thermometer</v-icon>
        {{ severity }}
      </v-chip>
    </div>
    <div v-else-if="severity == 'High'">
      <v-chip small class="ma-2">
        <v-icon left color="error">mdi-thermometer</v-icon>
        {{ severity }}
      </v-chip>
    </div>
    <div v-else-if="severity == 'Critical'">
      <v-chip small class="ma-2">
        <v-icon left color="purple">mdi-thermometer</v-icon>
        {{ severity }}
      </v-chip>
    </div>
    <div v-else>
      <v-chip small class="ma-2">
        <v-icon left color="grey">mdi-thermometer</v-icon>
        {{ severity }}
      </v-chip>
    </div>
  </div>
</template>

<script>
export default {
  name: "CaseSeverity",

  props: {
    severity: {
      type: String,
      required: true,
    },
  },
}
</script>
